import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app.module';
import {environment} from '../environments/environment';
import {enableProdMode, MissingTranslationStrategy} from '@angular/core';
import {I18N_PROVIDERS} from 'app/i18n/i18n.module';
import {APP_TRANSLATIONS} from 'app/i18n/i18n';
import { hmrBootstrap } from 'fw-core';

// disable debug data on prod profile to improve performance
if (environment.production) {
    enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule, {
    providers: [
        ...I18N_PROVIDERS
    ],
    missingTranslation: APP_TRANSLATIONS ? MissingTranslationStrategy.Warning : MissingTranslationStrategy.Ignore
});

if (environment.hmr) {
    if (module[ 'hot' ]) {
        hmrBootstrap(module, bootstrap);
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap();
}
