import { Injectable } from '@angular/core';

import { Principal } from '../../security/principal.service';
import { AuthServerProvider } from '../../security/auth-session.service';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    constructor(private principal: Principal, private authServerProvider: AuthServerProvider) {}

    login(credentials, callback?) {
        const cb = callback || function() {};

        return new Promise((resolve, reject) => {
            this.authServerProvider.login(credentials).subscribe(
                data => {
                    this.principal.identity(true).then(account => {
                        resolve(data);
                    });
                    return cb();
                },
                err => {
                    this.logout().subscribe();
                    reject(err);
                    return cb(err);
                }
            );
        });
    }

    logout() {
        return this.authServerProvider.logout().pipe(tap(() => {
            this.principal.authenticate(null);
        }));
    }
}
