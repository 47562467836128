import {Injectable} from '@angular/core';
import {AccountResourceEndpoint, AccountDTO} from 'app/apina/apina-api';
import { FwPrincipalService } from 'fw-core';

@Injectable({
    providedIn: 'root'
})
export class Principal extends FwPrincipalService<AccountDTO> {

    constructor(private account: AccountResourceEndpoint) {
        super(undefined);
        this.identity();
    }

    authenticate(identity) {
        this.setState(identity);
    }

    hasAnyAuthority(authorities: string[]): Promise<boolean> {
        return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (!this.state || !this.state.authorities) {
            return false;
        }

        for (let i = 0; i < authorities.length; i++) {
            if (this.state.authorities.includes(authorities[i])) {
                return true;
            }
        }

        return false;
    }

    hasAuthority(authority: string): Promise<boolean> {
        if (!this.state) {
            return Promise.resolve(false);
        }
        return this.identity().then(
            id => {
                return Promise.resolve(id.authorities && id.authorities.includes(authority));
            },
            () => {
                return Promise.resolve(false);
            }
        );
    }

    identity(force?: boolean): Promise<AccountDTO> {
        if (force === true) {
            this.setState(undefined);
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving a copy
        if (this.state) {
            return Promise.resolve(this.state);
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        // TODO pensar en si compensa chequear si hay cookie de sesión antes de hacer la petición

        return this.account
            .getAccount()
            .toPromise()
            .then(datos => {
                if (datos) {
                    this.setState(datos);
                } else {
                    this.setState(undefined);
                }
                return this.state;
            })
            .catch(err => {
                this.setState(undefined);
                return null;
            });
    }

    isAuthenticated(): boolean {
        return !!this.state;
    }

}
