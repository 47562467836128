import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {map} from 'rxjs/operators';
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class AuthServerProvider {
    constructor(private http: HttpClient) {
    }

    login(credentials): Observable<any> {
        const data =
            'username=' +
            encodeURIComponent(credentials.username) +
            '&password=' +
            encodeURIComponent(credentials.password) +
            '&remember-me=' +
            credentials.rememberMe +
            '&submit=Login';
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post('api/authentication', data, {headers});
    }

    logout(): Observable<any> {
        return this.http.post('api/logout', {}).pipe(map(response => {
            return response;
        }));
    }
}
