import {LOCALE_ID, NgModule, TRANSLATIONS, TRANSLATIONS_FORMAT} from '@angular/core';
import {APP_TRANSLATIONS} from 'app/i18n/i18n';
import {registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeGl from '@angular/common/locales/gl';

registerLocaleData(localeEs, 'es');
registerLocaleData(localeGl, 'gl');

export const I18N_PROVIDERS =
    [{provide: TRANSLATIONS, useValue: APP_TRANSLATIONS || []},
        {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'}];

@NgModule({
    imports: [],
    declarations: [],
    providers: [
        ...I18N_PROVIDERS,
        {
            provide: LOCALE_ID,
            useFactory: () => localStorage.getItem('locale') || 'es'
        }, ],
    exports: []
})
export class I18nModule {

}
