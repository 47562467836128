import {Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {Router} from '@angular/router';
import {LoginService} from 'app/auth/login/login.service';
import {LOGIN_STATE} from 'app/constants';
import { StateStorageService } from 'fw-core';

const STATUS_LOGIN_ERROR_OR_SESSION_EXPIRED = 401;

export class AuthExpiredInterceptor implements HttpInterceptor {
    constructor(private stateStorageService: StateStorageService, private injector: Injector) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap(
            () => {
            },
            (err: HttpErrorResponse) => {
                if (err.status === STATUS_LOGIN_ERROR_OR_SESSION_EXPIRED && !err.url.includes('/auth')) {
                    const destination = this.stateStorageService.getDestinationState();
                    if (destination !== null) {
                        const to = destination.destination;
                        const toParams = destination.params;
                        if (to.name === 'accessdenied') {
                            this.stateStorageService.storePreviousState(to.name, toParams);
                        }
                    }
                    const loginService: LoginService = this.injector.get(LoginService);
                    loginService.logout().subscribe();
                    const router: Router = this.injector.get(Router);
                    router.navigate([LOGIN_STATE]);
                }
            }
        ));
    }
}
