declare const require;
const locale = localStorage.getItem('locale');
let translations;
if (false) { // TODO Ivy no soporta la internacionalización en modo desarrollo...
    try {
        translations = require(`raw-loader!../../i18n/messages.${locale.toLowerCase()}.xlf`).default;
    } catch (e) {
        if (locale) {
            console.warn('No existe el fichero .xlf para el locale ' + locale);
        }
        translations = null;
    }
}
export const APP_TRANSLATIONS = translations;

