import {Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {sidebarCssClasses} from 'app/layout/shared/classes';

@Component({
  selector: 'app-sidebar',
  template: `<ng-content></ng-content>`
})
export class AppSidebarComponent implements OnChanges, OnInit {
  @Input() compact: boolean;
  @Input() display: any;
  @Input() fixed: boolean;
  @Input() minimized: boolean;
  @Input() offCanvas: boolean;

  lastCssDisplayClass: string;

    @HostBinding('class.sidebar') get sidebar() {
        return true;
    };

  constructor() {}

  ngOnInit() {
    this.displayBreakpoint(this.display);
    this.isCompact(this.compact);
    this.isFixed(this.fixed);
    this.isMinimized(this.minimized);
    this.isOffCanvas(this.offCanvas);
  }

  ngOnChanges(changes: SimpleChanges) {
      this.displayBreakpoint(this.display);
    }

  isCompact(compact: boolean): void {
    if (this.compact) { document.querySelector('body').classList.add('sidebar-compact'); }
  }

  isFixed(fixed: boolean): void {
    if (this.fixed) { document.querySelector('body').classList.add('sidebar-fixed'); }
  }

  isMinimized(minimized: boolean): void {
    if (this.minimized) { document.querySelector('body').classList.add('sidebar-minimized'); }
  }

  isOffCanvas(offCanvas: boolean): void {
    if (this.offCanvas) { document.querySelector('body').classList.add('sidebar-off-canvas'); }
  }

  fixedPosition(fixed: boolean): void {
    if (this.fixed) { document.querySelector('body').classList.add('sidebar-fixed'); }
  }

  displayBreakpoint(display: any): void {
      document.querySelector('body').classList.remove( this.lastCssDisplayClass);
    if (this.display !== false ) {
      this.display ?  this.lastCssDisplayClass = `sidebar-${this.display}-show` :  this.lastCssDisplayClass = sidebarCssClasses[0];
      document.querySelector('body').classList.add( this.lastCssDisplayClass);
    }
  }
}
